import React, { PureComponent, Image } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, AreaChart, Area
} from 'recharts';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, paper, Button, ButtonGroup, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';

export default class Chart extends PureComponent {
  state = {
    data: [],
    showDiv: null,
    nav: true
  }

  loadItem = async () => {
    await fetch('https://dev2.timestope.co.kr/v2/com.timestope.helper/get_unit_price').then(res => res.json())
      .then(res => { this.setState({ data: res.data.unit, nav: false }) })
      .then(() => { if (this.state.showDiv == 'Loading') { this.setState({ showDiv: 'MAIN' }) } })
  }

  componentDidMount() {
    this.interval = setInterval(this.loadItem, 30000); //30초 마다 새로고침 api
    this.setState({ showDiv: 'Loading' })
    this.loadItem();  // loadItem 호출

    // this.setState({showDiv: 'MAIN'})

  }

  render() {


    return (
      <div>

        {
          this.state.showDiv === 'Loading' ?
            <div>
              <img src='https://i.stack.imgur.com/ATB3o.gif' />
              <Typography gutterBottom variant="h5" component="h2" marg>
                LOADING DATA FROM API
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                IT DOESN'T TAKES A LOT
              </Typography>
            </div>

            :
            <div>
              <div>
                <Typography gutterBottom variant="h5" component="h2" marg>
                  TimeStope 장외 거래 평균 시세
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  전체 데이터의 1/3만큼(7,268 / 20,613)만 반영되어.. 모든 데이터를 적용하기 위한 알고리즘 개선 및 Fee 자산 지원 등 추가할 게 많습니다.
                </Typography>
                <ResponsiveContainer width='100%' aspect={2.3} >


                  <AreaChart width={730} height={250} data={this.state.data}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="based_date" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Area type="monotone" dataKey="av_unit_price.sell" stroke="#82ca9d" fillOpacity={1} fill="url(#colorUv)" />
                    <Area type="monotone" dataKey="av_unit_price.buy" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                    {/* <Area type="monotone" dataKey="total" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" /> */}
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
        }

      </div>

    );
  }
}