import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import TodoList from './TodoList.js'
import './App.css';
import Chart from './component/Chart.js';
// import React, { useState, useRef, useEffect } from 'react';
// import {v4 as uuidv4} from 'uuid';

// const LOCAL_STORAGE_KEY = 'todoApp.todos'

function App() {
//   const [todos, setTodos] = useState([{ id: 1, name: 'todo', complete: true }])
//   const todoNameRef = useRef()

//   useEffect(() => {
// const storedTodos = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
// if(storedTodos) setTodos(storedTodos)
//   }, [])



//   useEffect(() => {
//     localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(todos))
//   }, [todos])




// function toggleTodo(id) {
//   const newTodos = [...todos]
//   const todo = newTodos.find(todo => todo.id === id)
//   todo.complete = !todo.complete
//   setTodos(newTodos)
// }

//   function handleAddTodo(e) {
//     const name = todoNameRef.current.value
//     if (name == '') return
//     setTodos(prevTodos => {
//       return [...prevTodos, { id: uuidv4(), name: name, complete: false }]
//     })
//     todoNameRef.current.value = null
//   }

// function handleClearTodos() {
//   const newTodos = todos.filter(todo => !todo.complete);
//   setTodos(newTodos)
// }

  return (
    <div className="App">
      {/* <TodoList todos={todos} toggleTodo = {toggleTodo} />
      <input ref={todoNameRef} type="text" />
      <button onClick={handleAddTodo}>add tdo</button>
      <button onClick={handleClearTodos}>clear completed</button>
      <div>{todos.filter(todo => !todo.complete).length} left to do</div> */}
      <Chart />
    </div>
  );
}

export default App;
